// 存储对象
import {DEVICE_NAME, VIDEO_PARAMS} from "@/constant";

const setContent = (key, content) => {
    localStorage.setItem(key, JSON.stringify(content))
}

// 获取对象
const getContent = (key) => {
    const content = localStorage.getItem(key)
    return JSON.parse(content)
}

// 移除对象
const removeContent = (key) => {
    localStorage.removeItem(key)
}

// 存储设备型号
const setDevice = (key, model) => {
    localStorage.setItem(key, model);
}

// 获取设备型号
const getDevice = (key) => {
    return !!localStorage.getItem(key) === true ? Number(localStorage.getItem(key)) : -1
}
// 移除设备型号
const removeDevice = (key) => {
    localStorage.removeItem(key)
}

// 移除所有参数
const removeAllParams = () => {
    // 移除设备型号
    localStorage.removeItem(DEVICE_NAME)
    //  移除视频参数
    localStorage.removeItem(VIDEO_PARAMS)
}
export {
    setContent,
    getContent,
    removeContent,
    setDevice,
    getDevice,
    removeDevice,
    removeAllParams

}