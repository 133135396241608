import SimpleVideoReact from 'trtc-simple-react-plus-ipad'
import {useEffect, useRef, useState} from "react";
import RingOffAndFlipCamera from "@/component/RingOffAndFlipCamera";
import './index.scss'
import {listenerError, getContent, removeAllParams} from "@/utils";
import {VIDEO_PARAMS} from "@/constant";

const Ipad = () => {
    // 监听页面错误
    listenerError()
    // 获取视频链接参数
    const videoParams = getContent(VIDEO_PARAMS);
    const videoRef = useRef(null);
    // 是否挂断
    const [isRingOff, setIsRingOff] = useState(false)
    // 1:前置，0：后置
    const [cameraMode, setCameraMode] = useState(1)

    useEffect(() => {
        initVideo()
        return () => {
            clear()
        }
    }, [])

    // 视频初始化
    const initVideo = () => {
        if (videoRef.current) {
            videoRef.current.handleJoin()
        }
    }

    // 清除数据
    const clear = () => {
        if (videoRef.current) {
            videoRef.current.handleLeave();
        }
        removeAllParams()
    }

    // 切换摄像头
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.switchCamera()
        }
    }, [cameraMode]);

    // 挂断视频
    useEffect(() => {
        if (isRingOff) {
            clear()
        }
    }, [isRingOff])


    return <>
        {
            !isRingOff && <div className={"box"}>
                <div className={"video"}>
                    <SimpleVideoReact
                        ref={videoRef}
                        config={{
                            roomId: videoParams.roomId,
                            sdkAppId: videoParams.sdkAppId,
                            userId: videoParams.userId,
                            userSig: videoParams.userSig,
                            showLocal: true,
                            showRemote: true,
                            showDevice: true,
                            showCamera: true,
                            showMic: false,
                        }}
                        customStyle={{
                            width: "1280px",
                            height: "800px",
                            localStreamWidth: "1280px",
                            localStreamHeight: "800px",
                            remoteStreamWidth: "334px",
                            remoteStreamHeight: "180px",
                            mode: "vertical-L",
                        }}
                        remoteStatus={(status) => console.log("remoteStatus", status)}
                        localStatus={(status) => console.log("localStatus", status)}
                        clientStatus={(status) => console.log("clientStatus", status)}
                        otherStatus={(status) => console.log("otherStatus", status)}
                    ></SimpleVideoReact>

                    <div className="ring-off">
                        <RingOffAndFlipCamera
                            flipCameraFn={(e) => setCameraMode(e)}
                            ringOffFn={(e) => setIsRingOff(e)}
                        />
                    </div>
                </div>
            </div>
        }</>


}

export default Ipad