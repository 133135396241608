import '@/component/ConnectTest/index.scss'
import {Button, Form, Radio, Space} from "antd-mobile";
import {Input} from "antd-mobile/2x";
import BASE_URL from "@/config/domain";
// 视频通讯连接测试
const ConnectTest = () => {
    const [form] = Form.useForm()

    const onSubmit = async () => {
        try {
            // validateFields 会触发表单验证
            const values = await form.validateFields();
            // 执行提交操作
            submitUserInfo(values);
        } catch (errorInfo) {
            console.log('Form validation failed:', errorInfo);
        }
    };

    // 提交用户信息
    const submitUserInfo = (values) => {
        // 使用 fetch 发送 POST 请求
        fetch(`${BASE_URL}/connecting`, { // 请替换为实际的后端接口URL
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values)
        })
            .then(response => response.json()) // 解析响应为 JSON
            .then(data => {
                console.log(data.data); // 输出后端返回的响应
                window.location.href = data.data
            })
            .catch(error => {
                alert('请求失败，请稍后再试。');
                console.log(error);
            });
    }
    return <>
        <div className={"box"}>
            <Form
                form={form}  // 绑定 form 实例
                className="my-form"
                footer={
                    <Button block color="primary" onClick={() => form.submit()} size="small">
                        提交
                    </Button>
                }
                onFinish={onSubmit}  // 提交成功后的回调
            >
                <Form.Header>智云众康在线视频通话测试</Form.Header>

                <Form.Item
                    name="userId"
                    label="用户Id"
                    help="用户的唯一标识"
                    rules={[{required: true, message: '请输入用户id'}]}
                >
                    <Input type="text" placeholder="请输入用户id"/>
                </Form.Item>

                <Form.Item
                    name="roomId"
                    label="房间号"
                    help="加入同一个房间号的用户可以相互通讯"
                    rules={[{required: true, message: '请输入房间号'}]}
                >
                    <Input type="number" min={0} placeholder="请输入地址"/>
                </Form.Item>

                <Form.Item
                    name="deviceEnum"
                    label="设备类型"
                    help="系统会根据设备类型输出适配的UI"
                    rules={[{required: true, message: '请选择设备'}]}
                >
                    <Radio.Group size="small">
                        <Space direction="horizontal">
                            <Radio value="0">phone</Radio>
                            <Radio value="1">ipad</Radio>
                            <Radio value="2">computer</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </div>
    </>
}
export default ConnectTest