import {createBrowserRouter} from 'react-router-dom'
import Dispatch from "src/dispatch";
import Ipad from "@/pages/ipad";
import Error from "@/component/Error/index.";
import ConnectTest from "@/component/ConnectTest";

const index = createBrowserRouter([
    {
        path: "/",
        element: <Dispatch/>
    },
    {
        path: 'ipad',
        element: <Ipad/>
    },
    {
        path: '/error',
        element: <Error/>
    },
    {
        path:'test',
        element: <ConnectTest/>
    }

])

export default index