// 发生错误时调用
import router from "@/router";

// 监听错误，发生错误事跳转到非法页面
const listenerError = () => {
    window.onerror = function (message, source, lineno, colno, error) {
        // 错误发生时执行的代码
        router.navigate("/error").then(() => {
            console.error("页面发生错误!!!", error);
        });
        // 返回 true 表示错误已处理，浏览器不会再显示默认的错误提示
        return true;
    };
}

export default listenerError;