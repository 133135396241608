import router from "@/router";
import {PHONE, IPAD, COMPUTER} from "@/constant";

// 添加转发路径
const routerMap = new Map([
    [PHONE, '/hone'],
    [IPAD, '/ipad'],
    [COMPUTER, '/computer'],
])


/**
 * 路由转发函数
 * @param model 设备
 */
const routerDispath = (model) => {
    const path = routerMap.get(model)
    if (!!path) {
        router.navigate(path).then(() => {
            console.log("访问->", path)
        })
    } else {
        router.navigate('/error').then(() => {
            console.log("访问失败->", path)
        })
    }
    window.location.reload();
}

export {routerDispath}