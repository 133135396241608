import {useEffect} from "react";
import {listenerError, getDevice, setContent, setDevice, routerDispath} from "@/utils";
import {DEVICE_NAME, ROOM_ID, SDK_APP_ID, USER_ID, USER_SIG, VIDEO_PARAMS} from "@/constant";

const Dispatch = () => {
    // 页面错误监听
    listenerError()
    useEffect(() => {
        collectParams().then(() => {
            router()
        }).catch(reason => {
            throw reason
        })
    }, [])

    //收集视频通讯参数
    const collectParams = async () => {
        const params = new URLSearchParams(window.location.search);
        const roomId = params.get(ROOM_ID)
        const userId = params.get(USER_ID)
        const sdkAppId = params.get(SDK_APP_ID)
        const userSig = params.get(USER_SIG)
        const deviceName = params.get(DEVICE_NAME)
        if (!!roomId && !!userId && !!sdkAppId && !!userSig && !!deviceName) {
            const videoParams = {roomId: Number(roomId), sdkAppId: Number(sdkAppId), userId: userId, userSig: userSig}
            setContent(VIDEO_PARAMS, videoParams)
            setDevice(DEVICE_NAME, deviceName)
        }
    }

    // 路由
    const router = () => {
        const model = getDevice(DEVICE_NAME)
        console.log(model)
        // 根据设备型号选择不同的页面
        routerDispath(model)
    }
}

export default Dispatch
