import '@/component/Error/index.scss'
import {useEffect, useState} from "react";

const Error = () => {
    const [visitor, setVisitor] = useState({})

    useEffect(() => {
        getIpaddress();
    }, []);

    // 通过 ipify API 获取用户的公网 IP 地址
    const getIpaddress = () => {
        // 使用 ip-api 查询 IP 地址和地理位置信息
        fetch('http://ip-api.com/json')
            .then(response => response.json())
            .then(data => {
                setVisitor({
                    ip: `IP:${data.query}`,
                    city: data.city,
                    region: data.regionName,
                    country: data.country,
                })
            })
            .catch(error => console.error('查询 IP 地址时发生错误:', error));
    }

    return <div className={"box"}>
        <div>
            <div><h1>非法访问!!!</h1></div>
            <div className="vosotor">
                <span> {visitor.ip}</span>
                <span> {visitor.city}</span>
                <span> {visitor.region}</span>
                <span> {visitor.country}</span>
            </div>
        </div>

    </div>
}

export default Error;