// 请求后端接口环境配置
const environment = {
    development: "http://localhost:8080",
    production: "http://47.108.203.112:28080",
    test: "http://182.151.0.28:28080"
}
let BASE_URL
try {
    BASE_URL = environment[process.env.NODE_ENV];
} catch (e) {
    throw new Error(`Environment variable "${process.env.NODE_ENV}" not found: ${e}`);
}
console.log("当前", process.env.NODE_ENV, BASE_URL);

export default BASE_URL;