import './style.scss'
import camera from '@/assets/camera.svg'
import ringOff from '@/assets/ringOff.svg'
import {useState} from "react";
// 挂断和翻转镜头组件
const RingOffAndFlipCamera = ({ringOffFn, flipCameraFn}) => {

    // 1:前置，2：后置
    const [flipCameraMode, setflipCameraMode] = useState(1)
    const flipCameraHandler = () => {
        const flag = flipCameraMode === 0 ? 1 : 0
        setflipCameraMode(flag)
        return flag

    }

    return (
        <>
            <div className={"panel"}>
                <div className={"panel-content"}>
                    <div className={"content-left"} onClick={() => {
                        flipCameraFn(flipCameraHandler())
                    }}>
                        <img src={camera} alt={"..."}/>
                        <div className={"flip common"}>翻转</div>
                    </div>

                    <div className={"content-right"} onClick={e => {
                        ringOffFn(true)
                    }}>
                        <img src={ringOff} alt={"..."}/>
                        <div className={"flip common"}>挂断</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RingOffAndFlipCamera