// 存储视频通讯参数key
const VIDEO_PARAMS = "videoParams"
// 房间id
const ROOM_ID = "roomId"
// 用户id
const USER_ID = "userId"
// sdkAppid账号
const SDK_APP_ID = "sdkAppId"
// userSig签名
const USER_SIG = "userSig"

export {
    VIDEO_PARAMS,
    ROOM_ID,
    USER_ID,
    USER_SIG,
    SDK_APP_ID,
}