// import ReactDOM from 'react-dom/client';
// import React from "react";
// import App from "./App.js";
//
//
// const root = ReactDOM.createRoot(document.getElementById('root'));
//
// root.render(
//     // <React.StrictMode>
//     <App/>
//     // </React.StrictMode>
// )

import ReactDOM from 'react-dom/client';
import React from "react";
import {RouterProvider} from "react-router-dom";
import router from "@/router";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <RouterProvider router={router}/>
)