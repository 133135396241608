// 手机
const PHONE = 0
// ipad
const IPAD = 1
// 电脑
const COMPUTER = 2
// 储存设备key
const DEVICE_NAME ="deviceName"

export {
    PHONE,
    IPAD,
    COMPUTER,
    DEVICE_NAME
}